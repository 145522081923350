<template>
  <div>
    <section class="contact-with-us">
      <div class="container">
        <div class="your-num">{{ $t("Emailingizni qoldiring") }}</div>
        <div class="contact-wu-form">
          <form @submit.prevent="subscribe()">
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="form-card">
                  <label for="">Email:</label>
                  <div class="num-input-border">
                    <input
                      required
                      v-model="email"
                      type="email"
                      placeholder="example@email.com"
                      class="num-input"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div
                  class="form-card teleg"
                  :class="{ 'fly': sent, 'before-fly': !sent }"
                >
                  <input
                    :disabled="subscribeLoading"
                    type="submit"
                    :value="$t('yuborish')"
                  />
                  <span class="go-fly"></span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p class="error-message" v-for="er in errors" :key="er">{{ er }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { TweenLite, TimelineLite, Power1} from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
import gsap from 'gsap/all';
gsap.registerPlugin(TweenLite,TimelineLite,CSSPlugin, Power1)

export default {
  data() {
    return {
      sent: false,
      email: "",
      subscribeLoading: false,
      errors: [],
      flightPath : {
        curviness : 1.25,
        autoRotate : true,
        values : [
          { x: 100, y:-20 },
          { x: 200, y:-50 },
        ]
      }
    };
  },
  watch: {
    sent(value) {
      if (value)
        setTimeout(() => {
          this.sent = false;
        }, 2000);
    },
    email() {
      this.errors = [];
    },
  },
  methods: {
    subscribe() {
      this.errors = [];
      this.subscribeLoading = true;
     
      axios
        .post("subscribe/subscribe", {
          email: this.email,
        })
        .then(() => {
          this.gsapInit()
          this.sent = true;
          this.subscribeLoading = false;
          this.email = "";
          this.$toasted.show("Your email sent successfuly!", {
            duration: 3000,
            type: "",
            icon: "success-icon",
          });
          
        })
        .catch((error) => {
          this.subscribeLoading = false;
          this.errors = error.response.data.errors;
        });
    },
    gsapInit(){
      const tl = gsap.timeline()
    tl.to('.go-fly', 
      { x:1900, y:-800, rotation:-15, duration:1.5 }, 
    )
     tl.to('.go-fly', 
      { x:1900, y:1500, rotation:0, duration:0}, 
    )
    tl.to('.go-fly', 
      { x:-1900, y:1500, rotation:0, duration:0}, 
    )
    tl.to('.go-fly', 
      { x:0, y:0, rotation:0}, 
    )

    }
  },
  mounted(){
  }
};
</script>

<style>
.contact-with-us .contact-wu-form form .num-input {
  width: 100%;
}

/* @media (min-width: 1200px) {
  .fly::after {
    animation: fly 1s linear forwards;
  }
  .before-fly::after {
    animation: beforeFly 0.5s linear forwards;
  }
}
@keyframes fly {
  50% {
    top: -346px;
    left: -185px;
    transform: rotateY(20deg);
  }
  99% {
    top: -650px;
    left: 1245px;
  }
  100% {
    top: -650px;
    left: 1245px;
    display: none;
  }
}
@keyframes beforeFly {
  0% {
    top: 300px;
    left: -200px;
  }
  100% {
    top: -80px;
    left: 0;
  }
} */
</style>