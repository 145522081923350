import Vue from "vue";
import axios from "axios";
import Vuex from 'vuex'
import L from 'leaflet';
Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        current_lang: 'ru',
        lang: false,
        zoom: 10,
        posts: [],
        post: '',
        header2: true,
        nearby: [],
        center: [41.311081, 69.240562],
        reInitMap: true,
        codeSent: false,
        orgPage: false,
        authorized: true,
        organizations: [],
        orgPageCount: 1,
        orgCurrentPage: 1,
        organization: '',
        dishes: [],
        dishPageCount: 1,
        dishCurrentPage: 1,
        dish: {},
        user: {},
        settings: [],
        about: '',
        more: '',
        signUpTurist: '',
        signUpCheef: '',
        signIn: '',
        loyiha: '',
        regions: [],
        districts: [],
        regionId: '',
        districtId: '',
        filteredOrgs: [],
        signUpType: 'turist',
        showType: true,
        banners: [],
        contact: '',
        bookings: [],
        goEatSingle: false
    },
    getters: {

    },
    actions: {
        banners({ commit }) {
            axios.get('banner')
                .then((res => {
                    commit('SET_BANNERS', res.data.data)
                }))
                .catch(err => {
                    console.error(err);
                })
        },
        postAction({ commit }) {
            axios.get('post/index')
                .then((res => {
                    commit('SET_POSTS', res.data.data)
                }))
                .catch(err => {
                    console.error(err);
                })
        },
        getPost({ commit }, slug) {
            commit('SET_POST', {})
            axios.get('post/' + slug)
                .then(response => {
                    commit('SET_POST', response.data.data)
                })
        },
        getOrganizations({ commit, state }) {
            if (state.orgCurrentPage <= state.orgPageCount)
                axios.get('organization?include=image')
                .then((res => {
                    state.orgCurrentPage = state.orgCurrentPage + 1
                    state.orgPageCount = res.data.meta.pageCount
                    commit('SET_ORGANIZATIONS', res.data.data)
                    commit('SET_NEARBY', res.data.data)
                    commit('GET_FILTERED_ORG', res.data.data)
                }))
                .catch(err => {
                    console.error(err);
                })
        },
        getOrganizationsMore({ commit, state }) {
            if (state.orgCurrentPage <= state.orgPageCount)
                axios.get('organization?include=image&page=' + state.orgCurrentPage)
                .then((res => {
                    state.orgCurrentPage = state.orgCurrentPage + 1
                    state.orgPageCount = res.data.meta.pageCount
                    commit('SET_ORGANIZATIONS_MORE', res.data.data)
                }))
                .catch(err => {
                    console.error(err);
                })
        },
        getOrganization({ commit }, payload) {
            commit('SET_ORGANIZATION', {})
            axios.get('organization/' + payload + '?include=image&_f=json')
                .then(response => {
                    commit('SET_ORGANIZATION', response.data.data)
                })
        },
        getDishes({ commit, state }, payload) {
            axios.get('organization/' + payload + '/products?include=image&_f=json&per-page=6')
                .then(response => {
                    state.dishCurrentPage = state.dishCurrentPage + 1
                    state.dishPageCount = response.data.meta.pageCount
                    commit('SET_DISHES', response.data.data)
                })
        },
        getDishesMore({ commit, state }, payload) {
            if (state.dishCurrentPage <= state.dishPageCount)
                axios.get('organization/' + payload + '/products?include=image&_f=json&per-page=6&page=' + state.dishCurrentPage)
                .then(response => {
                    state.dishCurrentPage = state.dishCurrentPage + 1
                    state.dishPageCount = response.data.meta.pageCount
                    commit('SET_DISHES_MORE', response.data.data)
                })
        },
        getDish({ commit }, payload) {
            commit('SET_DISH', {})
            axios.get('organization/' + payload.orgId + '/products/' + payload.dishId + '?include=images&_f=json')
                .then(response => {
                    commit('SET_DISH', response.data.data)
                })
        },
        nearbyAction({ commit }, payload) {
            axios.get('organization/nearby/' + payload.lat + '/' + payload.lng)
                .then(response => {
                    commit('SET_NEARBY', response.data.data)
                })
                .catch(error => {
                    console.error(error);
                })
        },
        settings({ commit }) {
            axios.get('settings')
                .then(response => {
                    commit('SET_SETTINGS', response.data.data)
                })
        },
        about({ commit }) {
            axios.get('page/about')
                .then(response => {
                    commit('SET_ABOUT', response.data.data)
                })
        },
        more({ commit }) {
            axios.get('page/more')
                .then(response => {
                    commit('SET_MORE', response.data.data)
                })
        },
        signUpTurist({ commit }) {
            axios.get('page/signup-turist')
                .then(response => {
                    commit('SET_SIGNUPTURIST', response.data.data)
                })
        },
        signUpCheef({ commit }) {
            axios.get('page/signup-cheef')
                .then(response => {
                    commit('SET_SIGNUPCHEEF', response.data.data)
                })
        },
        signIn({ commit }) {
            axios.get('page/signin')
                .then(response => {
                    commit('SET_SIGNIN', response.data.data)
                })
        },
        loyiha({ commit }) {
            axios.get('page/loyiha')
                .then(response => {
                    commit('SET_LOYIHA', response.data.data)
                })
        },
        contact({ commit }) {
            axios.get('page/contact')
                .then(response => {
                    commit('SET_CONTACT', response.data.data)
                })
        },
        getRegions({ commit }) {
            axios.get('country/region')
                .then(response => {
                    commit('SET_REGIONS', response.data.data)
                })
        },
        getDistricts({ commit, dispatch }, regionId) {
            commit('SET_DISTRICTS', [])
            dispatch('filterOrgReg', regionId)
            axios.get('country/district?region_id=' + regionId)
                .then(response => {
                    commit('SET_DISTRICTS', response.data.data)
                })
        },
        filterOrgReg({ commit }, regionId) {
            axios.get('organization?include=image&region_id=' + regionId)
                .then(response => {
                    console.log(response.data.data);
                    commit('GET_FILTERED_ORG', response.data.data)
                    commit('SET_NEARBY', response.data.data)
                })
        },
        filterOrgDis({ commit }, region) {
            axios.get('organization?include=image&region_id=' + region.regionId + '&district_id=' + region.districtId)
                .then(response => {
                    console.log(response.data.data);
                    commit('GET_FILTERED_ORG', response.data.data)
                    commit('SET_NEARBY', response.data.data)
                })
        },
        allOrganization({ commit, state }) {
            commit('GET_FILTERED_ORG', state.organizations)
            commit('SET_NEARBY', state.organizations)
            state.regionId = ''
            state.districtId = ''
            state.allOrganization = true
        },
        bookings({ commit }) {
            const token = localStorage.getItem('token')
            axios.get("booking?include=product", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    commit('SET_BOOKINGS', res.data.data)
                });
        }

    },
    mutations: {
        SET_BANNERS(state, payload) {
            state.banners = payload
        },
        GET_LANG(state, payload) {
            state.lang = payload
        },
        SET_LANG(state, payload) {
            state.current_lang = payload
        },
        SET_POSTS(state, posts) {
            state.posts = posts
        },
        SET_POST(state, post) {
            state.post = post
        },
        SET_HEADER(state, payload) {
            state.header2 = payload
        },
        SET_NEARBY(state, payload) {
            state.nearby.length = 0
            payload.forEach(el => {
                if (el.lat != null && el.lon != null)
                    state.nearby.push({
                        'latlng': L.latLng(el.lat, el.lon),
                        'title': el.title,
                        'id': el.id
                    })
                state.center[0] = el.lat
                state.center[1] = el.lon
            });
            state.reInitMap = false
            setTimeout(() => {
                state.reInitMap = true
            }, 200);
        },
        USER_DATA(state, payload) {
            state.user = payload
            if (state.user.token) state.authorized = true
            else state.authorized = false
            localStorage.setItem('token', state.user.token)
        },
        SENT_CODE(state, payload) {
            state.codeSent = payload
        },
        IS_ORG_PAGE(state, payload) {
            state.orgPage = payload
        },
        SET_ORGANIZATIONS(state, payload) {
            state.organizations = payload
        },
        SET_ORGANIZATIONS_MORE(state, payload) {
            state.organizations = state.organizations.concat(payload)
        },
        SET_ORGANIZATION(state, payload) {
            state.organization = payload
        },
        SET_DISHES_MORE(state, payload) {
            state.dishes = state.dishes.concat(payload)
        },
        SET_DISHES(state, payload) {
            state.dishes = payload
        },
        SET_DISH(state, payload) {
            state.dish = payload
        },
        CHANGE_ZOOM(state, payload) {
            state.zoom = state.zoom + payload
        },
        SET_SETTINGS(state, payload) {
            state.settings = payload
        },
        SET_ABOUT(state, payload) {
            state.about = payload
        },
        SET_MORE(state, payload) {
            state.more = payload
        },
        SET_SIGNUPTURIST(state, payload) {
            state.signUpTurist = payload
        },
        SET_SIGNUPCHEEF(state, payload) {
            state.signUpCheef = payload
        },
        SET_SIGNIN(state, paylaod) {
            state.signIn = paylaod
        },
        SET_LOYIHA(state, payload) {
            state.loyiha = payload
        },
        SET_CONTACT(state, payload) {
            state.contact = payload
        },
        RESET_DISH(state) {
            state.dishes.length = 0
            state.dishCurrentPage = 1
            state.dishPageCount = 1
        },
        SET_REGIONS(state, payload) {
            state.regions = payload
        },
        SET_DISTRICTS(state, payload) {
            state.districts = payload
        },
        SET_REGION_ID(state, payload) {
            state.regionId = payload
            state.districtId = ''
        },
        SET_DISTRICT_ID(state, payload) {
            state.districtId = payload
        },
        GET_FILTERED_ORG(state, payload) {
            state.filteredOrgs = payload
        },
        CHANGE_SIGNUP_TYPE(state, payload) {
            state.signUpType = payload
        },
        SHOW_SIGNUP_TYPE(state, payload) {
            state.showType = payload
        },
        SET_BOOKINGS(state, payload) {
            state.bookings = payload
        },
        GO_EAT_SINGLE(state, payload) {
            state.goEatSingle = payload
        }
    }
})