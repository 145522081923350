<template>
  <header>
    <div
      class="container header-wrapper header-3"
      :class="{
        'header-2': this.$store.state.header2,
        'header-3': this.$store.state.authorized,
      }"
    >
      <nav class="my-navbar">
        <router-link to="/" class="logo">
          <img src="../../assets/images/logo.svg" alt="" />
        </router-link>
        <div v-if="false" class="left" :class="{ active: selectActive }">
          <label for="location" class="navbar-select">
            <div class="loc-img">
              <img
                @click="getLocation()"
                src="../../assets/images/icons/location.svg"
                alt=""
              />
            </div>
            <div class="my-select">
              <div class="custom-select">
                {{ $t("Shaharni tanlang") }}
              </div>
            </div>
          </label>
          <div class="select-options">
            <div
              class="option"
              v-for="index in 4"
              :key="index"
              @click="selectActive = false"
            >
              {{ $t("Shaharni tanlang") }}
            </div>
          </div>
        </div>
        <div v-else class="left go-home">
          <router-link to="/" class="navbar-select link-to-home">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M20.339 7.65948C21.009 8.17848 21.42 8.94948 21.49 9.78848L21.5 9.98948V18.0985C21.5 20.1885 19.849 21.8885 17.78 21.9985H15.79C14.839 21.9795 14.07 21.2395 14 20.3095L13.99 20.1685V17.3095C13.99 16.9985 13.759 16.7395 13.45 16.6885L13.36 16.6785H10.689C10.37 16.6885 10.11 16.9185 10.07 17.2185L10.06 17.3095V20.1595C10.06 20.2185 10.049 20.2885 10.04 20.3385L10.03 20.3595L10.019 20.4285C9.9 21.2795 9.2 21.9285 8.33 21.9895L8.2 21.9985H6.41C4.32 21.9985 2.61 20.3595 2.5 18.2985V9.98948C2.509 9.13848 2.88 8.34848 3.5 7.79848L9.73 2.78848C11 1.77948 12.78 1.73948 14.089 2.66848L14.25 2.78848L20.339 7.65948ZM20.099 18.2585L20.11 18.0985V9.99848C20.099 9.56948 19.92 9.16848 19.61 8.86948L19.48 8.75848L13.38 3.87848C12.62 3.26848 11.54 3.23948 10.74 3.76848L10.589 3.87848L4.509 8.77948C4.16 9.03848 3.95 9.42848 3.9 9.83848L3.889 9.99848V18.0985C3.889 19.4285 4.929 20.5185 6.25 20.5985H8.2C8.42 20.5985 8.61 20.4495 8.639 20.2395L8.66 20.0595L8.67 20.0085V17.3095C8.67 16.2395 9.49 15.3695 10.54 15.2885H13.36C14.429 15.2885 15.299 16.1095 15.38 17.1595V20.1685C15.38 20.3785 15.53 20.5595 15.73 20.5985H17.589C18.929 20.5985 20.019 19.5695 20.099 18.2585Z"
                fill="white"
              />
            </svg>
            <span>{{ $t("Bosh sahifa") }}</span>
          </router-link>
        </div>
        <div v-if="$store.state.authorized">
          <router-link
            class="my-prof-icon"
            :to="'/' + $i18n.locale + '/settings'"
          >
            <img src="../../assets/images/icons/prof.svg" alt="" />
          </router-link>
        </div>
        <div class="right" :class="{ active: navOpen }">
          <ul class="my-navbar-ul">
            <li v-for="(link, index) in navlinks" :key="index">
              <router-link
                :to="'/' + $i18n.locale + link.url"
                @click.native="navOpen = false"
              >
                {{ link.name }}
              </router-link>
            </li>
          </ul>
          <router-link
            v-if="!$store.state.authorized"
            :to="'/' + $i18n.locale + '/signIn'"
            class="first-btn"
          >
            <img
              class="log-btn-img"
              src="../../assets/images/icons/Profile.svg"
              alt=""
            />
            <span>{{ $t("Kirish") }}</span>
          </router-link>
          <div
            v-else
            class="first-btn info-2 info-2-1"
            :class="{ activated: togglerMydrop3 }"
            @click="togglerMydrop3 = !togglerMydrop3"
          >
            <div class="my-drop-down">
              <div class="pro-img">
                <img
                  v-if="user.image != null"
                  :src="user.image.small"
                  class="user-prof-img"
                />
                <img v-else src="../../assets/images/png/pro-img.png" alt="" />
              </div>
              <div class="pro-info">
                <div class="pro-name">
                  {{ user.first_name }} {{ user.last_name }}
                </div>
                <div class="pro-stat">{{ $t("TURIST") }}</div>
              </div>
              <img
                src="../../assets/images/icons/arrow-down.svg"
                alt=""
                class="my-arrow-img"
              />
            </div>
            <div class="drop-down-menu">
              <div class="dm-content">
                <router-link :to="'/' + $i18n.locale + '/bookings'">{{
                  $t("Buyurtmalar")
                }}</router-link>
                <router-link :to="'/' + $i18n.locale + '/settings'">{{
                  $t("Sozlamalar")
                }}</router-link>
                <a @click="logOut()">{{ $t("Chiqish") }}</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <button
        class="hamburger hamburger--spin"
        id="my-button"
        :class="{ 'is-active': navOpen }"
        type="button"
        @click="navOpen = !navOpen"
      >
        <span class="hamburger-box"><span class="hamburger-inner"></span></span>
      </button>
      <div
        class="lang-drop"
        :class="{ active: langOpen }"
        @click="langOpen = !langOpen"
      >
        <a class="drop-active">
          <span>{{ lang }}</span>
          <img src="../../assets/images/icons/Arrow - Down.svg" alt=">" />
        </a>
        <div class="drop-menu">
          <a class="item" v-if="lang != 'uz'" @click="changeLang('uz')">uz</a>
          <a class="item" v-if="lang != 'ru'" @click="changeLang('ru')">ru</a>
          <a class="item" v-if="lang != 'en'" @click="changeLang('en')">en</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  data() {
    return {
      lang: this.$i18n.locale,
      coordinates: {
        lat: 0,
        lng: 0,
      },
      selectActive: false,
      navOpen: false,
      langOpen: false,
      selectShow: false,
      togglerMydrop3: false,
      navlinks: [
        {
          name: this.$t("Loyiha haqida"),
          url: "/about",
        },
        {
          name: this.$t("Yangiliklar"),
          url: "/news",
        },
        {
          name: this.$t("Mahallalar"),
          url: "/organization",
        },
        {
          name: this.$t("Aloqa"),
          url: "/contact",
        },
      ],
    };
  },
  methods: {
    changeLang(locale) {
      this.lang = locale;
      this.$i18n.locale = locale;
      this.$router
        .push({
          params: {
            lang: locale,
          },
        })
        .then(() => {
          localStorage.setItem("lang", locale);
          axios.defaults.headers = {
            "Accept-Language": locale,
          };
          document.querySelector("html").setAttribute("lang", locale);
          this.$router.go();
        });
    },
    getLocation() {
      this.$getLocation({})
        .then((coordinates) => {
          this.coordinates = coordinates;
          this.$store.dispatch("nearbyAction", this.coordinates).then(() => {
            if (this.$route.path !== this.$i18n.locale + "/search")
              this.$router.push("/" + this.$i18n.locale + "/search");
          });
        })
        .catch(() => {
          this.$toasted.show("Location not allowed", {
            duration: 3000,
            type: "error",
            icon: "error-icon",
          });
        });
      this.$store.commit("CHANGE_ZOOM", 5);
    },
    logOut() {
      this.$store.commit("USER_DATA", {})
      this.$router.push({ name : 'signIn' })
    },
  },
  computed: {
    ...mapState(["user"]),
  },
  updated() {},
  mounted() {
    /////
    const token = localStorage.getItem("token");
    if (token != "undefined" && token != null) {
      axios
        .get("user/get-me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.$store.commit("USER_DATA", response.data.data);
        })
        .catch(()=>{
          this.$store.commit("USER_DATA", {});
        })
    }
    else {
       this.$store.commit("USER_DATA", {});
    }
  },
  created() {},
};
</script>
<style>
.left.go-home::after {
  content: none;
}
.user-prof-img {
  border-radius: 8px;
}
.wrap-location-img {
  padding-right: 10px;
  border-right: 1px solid #fff;
}
header {
  margin-top: 15px;
}
.left {
  position: relative;
}
.left::after {
  content: url("../../assets/images/icons/arrow-down-lg.svg");
  position: absolute;
  top: 35%;
  right: 25px;
  transition: all ease 0.3s;
}
.left.active::after {
  transform: rotate(180deg);
  top: 30%;
}
@media (max-width: 768px) {
  .left::after {
    content: none;
  }
}
.left.active .navbar-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select-options {
  position: absolute;
  border: 2px solid #fff;
  border-top: 0;
  width: 100%;
  border-radius: 0 0 8px 8px;
  background: #fc772c;
  display: none;
  top: 100%;
}
.left.active .select-options {
  display: block;
}
.option {
  padding: 20px 0;
  text-align: center;
  color: #fff;
}
.option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.my-prof-icon {
  width: 50px;
  display: none;
  margin-right: 10px;
}
@media (max-width: 1199px) {
  .header-3 .my-prof-icon {
    display: block;
  }
}
@media (max-width: 575px) {
  .site-wrapper .header-3 .my-navbar .left .link-to-home {
    padding: 0 15px;
    border: 2px solid #fff;
  }
}
@media (max-width: 575px) {
  .m-taomlar .manage-card {
    display: block;
  }
}
.about-us-page .au-first .au-s-links .text span:after {
  bottom: 7px !important;
}
</style>

<style scoped>
.my-select {
  cursor: pointer;
}
.navbar-select img {
  cursor: pointer;
}
.my-select .custom-select select {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  outline: none;
  border: 0;
  color: #fff;
  font-size: 18px;
}
.my-select .custom-select option {
  background: #fc772c;
  min-width: 500px;
  border: 1px solid #fff;
  border-radius: 4px;
  outline: none;
}
.my-select .custom-select option:hover {
  background: #fff;
  color: #fc772c;
}
.my-drop-down {
  background: #fff;
  border-radius: 10px;
  font-family: Baloo2;
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  color: #252322;
  display: flex;
  height: 60px;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
}
.logo {
  width: 75px;
  display: flex;
}
.logo img {
  max-width: 100%;
  height: 54px;
}
</style>
<style>
@media (max-width: 768px) {
  .left {
    display: none !important;
  }

  .my-prof-icon img {
    width: 30px;
  }
}
</style>