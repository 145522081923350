<template>
    <l-map v-if="reInitMap" :zoom="11.3" :center="center" @click="addMarker" ref="leafletMap">
      <l-tile-layer :url="url" :attribution="attribution"> </l-tile-layer>
      <l-marker
        v-for="(item, index) in nearby"
        :key="index"
        :lat-lng="item.latlng"
        :draggable="false"
      >
        <l-icon :icon-size="[50, 45]" class-name="my-leftlet-icon nearby">
          <div class="headline">
              <router-link :to="'/'+$i18n.locale+'/organization/'+item.id+'/'+item.title.replace(' ', '-')">
                {{ item.title }}
              </router-link>
          </div>
          <img src="../../assets/images/leflet-icon-with-shadow.svg" />
        </l-icon>
      </l-marker>
  </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { mapState } from 'vuex'
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
  },
  data() {
    return {
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: "Map data &copy; OpenStreetMap contributors",
      layer: new L.TileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png"),
    };
  },
  computed: {
    ...mapState(['nearby', 'reInitMap','center'])
  },
  methods: {
    addMarker(event) {
      console.log(event.latlng.lat);
      console.log(event.latlng.lng);
    },
  },
  mounted() {},
};
</script>

<style>
.vue2leaflet-map {
  border-radius: 50px;
}
.leaflet-container .leaflet-marker-pane img {
  object-fit: contain;
}
.my-leftlet-icon {
  padding: relative;
}
.my-leftlet-icon img {
  width: 100%;
  height: 100%;
}
.my-leftlet-icon .headline {
  position: absolute;
  top: -56px;
  right: -124px;
  background-image: url("../../assets/images/headline-bg.svg");
  background-size: contain;
  width: 170px;
  min-height: 70px;
  background-repeat: no-repeat;
  cursor: default;
}
.my-leftlet-icon .headline a {
  display: block;
  word-break: break-all;
  padding: 16px 12px;
  font-family: Baloo2;
  color: #252322;
}
.my-leftlet-icon.nearby .headline a {
  cursor: pointer;
}
img.leaflet-tile.leaflet-tile-loaded {
  filter: grayscale(100%);
}
 .myMap, .map {
        position: relative;
        box-sizing: content-box;
    }
    .myMap::after, .map::after {
        content: none;
        position: absolute;
        top: 10px;
        left: 10px;
        right: 40px;
        bottom: 30px;
        border-radius: 50px;
        backdrop-filter: grayscale(100%);
        z-index: 555;
        pointer-events: none;
    }
</style>