<template>
  <div class="col-lg-8 col-xl-6 col-md-11 col-sm-12 col-12 mar-bot">
        <router-link @click.native="getData()" :to="'/'+$i18n.locale+'/organization/'+orgId+'/'+legal_name.replace(' ','-')" class="rek-card">
            <div class="rek-img">
                <img v-if="orgLogo!=null" :src="orgLogo.thumbnails.small" alt="">
                <img v-else  src="../../assets/images/default-img.svg" alt="logo not found :(">
            </div>
            <div class="texts">
                <div class="e-name">{{ legal_name }}</div>
                <div class="e-sub">{{ adress }}</div>
                <div class="e-num">{{ contact }}</div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props : {
        orgLogo : {
            default : null,
            required : true
        },
         legal_name : {
            default : 'organization',

        },
         adress : {
            default : '',
        },
         contact : {
            default : '',
        },
        orgId : {
            default : 0
        }
        
    },
    methods:{
        getData(){
            this.$store.commit('RESET_DISH')
            this.$store.dispatch('getDishes', this.orgId)
            this.$store.dispatch('getOrganization', this.orgId)
        }
    }
}

</script>

<style>

</style>