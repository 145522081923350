<template>
  <footer>
    <div class="my-footer">
      <section class="footer-first">
        <div class="container footer-f-container">
          <div class="row">
            <div class="col-lg-7 col-md-12">
              <div class="footer-link">
                {{ $t("Text for footer, some text") }}
              </div>
            </div>
            <div class="col-lg-5 col-md-12">
              <div class="footer-app-links">
                <div class="app-links">
                  <div
                    class="app-google mar-bot"
                    v-if="play_market !== undefined"
                  >
                    <a :href="play_market.value" target="_blank">
                      <div class="link-img">
                        <img src="../../assets/images/icons/g-play.svg" />
                      </div>
                      <div class="link-texts">
                        <div class="link-title">{{ play_market.title }}</div>
                        <div class="link-sub">Google Play</div>
                      </div>
                    </a>
                  </div>
                  <div class="app-apple" v-if="app_store !== undefined">
                    <a :href="app_store.value" target="_blank">
                      <div class="link-img">
                        <img src="../../assets/images/icons/app-store.svg" />
                      </div>
                      <div class="link-texts">
                        <div class="link-title">{{ app_store.title }}</div>
                        <div class="link-sub">App Store</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="footer-second">
        <div class="container footer-s-container">
          <div class="row">
            <div class="col-lg-7 col-md-12">
              <div class="my-footer-nav">
                <router-link
                  v-for="item in organizations.slice(0, 12)"
                  :key="item.id"
                  :to="
                    '/' +
                    $i18n.locale +
                    '/organization/' +
                    item.id +
                    '/' +
                    item.title.replace(' ', '-')
                  "
                  @click.native="$store.dispatch('getOrganization', item.id)"
                >
                  {{ item.title }}
                </router-link>
              </div>
            </div>
            <div class="col-lg-5 col-md-12 fit-content">
              <div class="footer-contacts">
                <a href="#!" class="reg-info">
                  <img src="../../assets/images/icons/reginfo7.svg" alt="" />
                  <p>
                    {{
                      $t(
                        "Mobil ilova yoki veb saytimizdan ro’yhatdan o’tish tugmasini bosasiz"
                      )
                    }}
                  </p>
                </a>
                <a href="#!" class="reg-info">
                  <img src="../../assets/images/icons/reginfo8.svg" alt="" />
                  <p>
                    {{
                      $t("Ism sharifingizni va telefon raqamingizni kiriting")
                    }}
                  </p>
                </a>
                <a href="#!" class="reg-info">
                  <img src="../../assets/images/icons/reginfo9.svg" alt="" />
                  <p>
                    {{
                      $t(
                        "Mobil ilovamizni yuklab oling va taomlarga buyurtma bering"
                      )
                    }}
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="copyright">
        <div class="container">
          <div class="copyright-wrapper">
            <p v-if="copy_right !== undefined">
              {{ copy_right.title }}
            </p>
            <a href="https://rteco.uz/">
              <img src="../../assets/images/icons/rteco.svg" alt="" />
            </a>
          </div>
        </div>
      </section>
    </div>
    <div class="test" v-if="test!='active'">  
          <div class="test-text">{{ $t('Veb sahifa test rejimida ishlamoqda!') }}</div>
          <a class="x-btn" @click="hideTest()"><img src="@/assets/images/close.png" alt=""></a>
        </div>
  </footer>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      test : localStorage.getItem('test')
    }
  },
  computed: {
    ...mapState(["settings", "organizations"]),
    app_store() {
      return this.settings.find((el) => el.slug == "app_store");
    },
    play_market() {
      return this.settings.find((el) => el.slug == "play_market");
    },
    copy_right() {
      return this.settings.find((el) => el.slug == "copy_right");
    },
  },
  methods: {
    hideTest(){
      this.test = 'active'
      localStorage.setItem('test', 'active')
    }
  },
  mounted() {
    this.$store.dispatch("settings");
  },
};
</script>

<style scoped>
 .footer-contacts {
  align-items: flex-start;
}
</style>