<template>
    <router-link :to="'/'+$i18n.locale+'/news/'+postId+'/'+slug" class="news-cards" @click.native="$store.dispatch('getPost', postId)">
        <div class="n-data">
            <img src="../../assets/images/icons/time.svg" alt="">{{  monthFormat(date*1000)}}</div>
        <div class="n-title">{{ title }}</div>
        <div class="n-sub">{{ anons }}</div>
    </router-link>
</template>

<script>
import { monthFormat } from '@/utils/index'
export default {
  props: ["date", "title", "anons", "slug", "postId"],
  methods : {
      monthFormat,
  }
};
</script>

<style>
</style>