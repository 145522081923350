<template>
  <div id="app">
   
    <div class="site-wrapper" v-if="this.$store.state.lang">
       
      <comp-header></comp-header>
      <div class="site-inner">
        <router-view></router-view>
      </div>
      <comp-footer></comp-footer>
    </div>
    <div v-else class="site-loader">
        <site-loader></site-loader>
    </div>
  </div>
</template>

<script>
import compHeader from "./components/headerFooter/Header";
import compFooter from "./components/headerFooter/Footer";
export default {
  name: "App",
  data() {
    return {
      
    }
  },
  components: {
    compHeader,
    compFooter,
  },
  methods: {
   
  },
  computed: {},
  mounted() {
    this.$store.dispatch("getOrganizations");
    this.$store.dispatch("postAction");
    this.$store.dispatch('getRegions')
  },
};
</script>
<style>
@import url('./assets/css/bootstrap-grid.min.css');
@import url('./assets/css/main.css');
html, body {
  scroll-behavior: auto;
}
body {
  overflow-x: hidden;
}
.my-list {
  list-style: disc;
}
.site-inner {
  min-height: 10vh;
}
.site-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fc772c;
  background-image: url(/img/header-2.c5e1f2ff.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0
}
.test {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 60vw;
    z-index: 99999;
    font-size: 24px;
    font-family: Comfortaa;
    line-height: 160%;
    padding: 32px 55px;
    background-image: url(/img/bg-main.679957d7.svg);
    background-position: 100% 0;
    background-color: #fc772c;
    color: #fff;
}
.x-btn {
 position: absolute;
    right: 0px;
    top: -41px;
}
.x-btn img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
@media (max-width:1250px) {
  .test {
    font-size: 26px;
  }
}
@media (max-width:992px) {
  .test {
    font-size: 24px;
    width: auto;
    left: 15px;
    right: 15px;
  }
}
@media (max-width:576px) {
  .test {
    font-size: 18px;
    width: 80vw;
    left: 0;
    right: auto
  }
  .x-btn {
  top: -45px;
}
}
</style>
