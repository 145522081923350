import Vue from 'vue'
import App from './App.vue'
///
import loader from './components/elements/Loader.vue'
Vue.component('loader', loader)
    ///
    ///
import siteLoader from './components/elements/SiteLoader.vue'
Vue.component('siteLoader', siteLoader)
    ///
import { store } from './store/store'
import { routes } from './router/routes'
///
import VueRouter from 'vue-router'
Vue.use(VueRouter)
    ////
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
    //
import SocialSharing from 'vue-social-sharing'
Vue.use(SocialSharing, {
    networks: {
        gmail: 'https://mail.google.com/mail/u/0/#inbox?compose=new',
        instagram: 'https://instagram.com'
    }
});
///
import VueLocation from 'vue-browser-geolocation'
Vue.use(VueLocation)
    ///
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
    // import 'bootstrap/dist/css/bootstrap.css'
    //////
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
Vue.use(Datetime)
    /////
import Toasted from 'vue-toasted'
Vue.use(Toasted, {
    iconPack: 'custom-class',
    routes
})
axios.defaults.baseURL = 'https://api.gastromahalla.uz/v1'

// Vue.config.productionTip = falsex`   
const router = new VueRouter({
        routes,
        mode: 'history',
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            } else {
                return { x: 0, y: 0 }
            }
        }
    })
    /// vue-i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'ru',
    fallbackLocale: localStorage.getItem('lang') || 'ru',
    missing: (locale, key, vm, values) => createMissingMessages(locale, key, vm, values)
})
axios.defaults.headers = {
    'Accept-Language': i18n.locale
}

function createMissingMessages(locale, key, vm, values) {
    axios.post("translation/create", { "message": key })
    console.log(vm, values);

}
const loadedLanguages = []
export function setI18nLanguage(lang) {
    i18n.locale = lang
    document.querySelector('html').setAttribute('lang', lang)
    localStorage.setItem('lang', lang)
    return lang
}

export function loadLanguageAsync(lang) {
    if (loadedLanguages.includes(lang)) {
        if (i18n.locale !== lang) setI18nLanguage(lang)
        return Promise.resolve()
    }
    return axios.get('translation')
        .then(response => {
            let msgs = response.data.data
            loadedLanguages.push(lang)
            i18n.setLocaleMessage(lang, msgs)
            setI18nLanguage(lang)
            store.commit('GET_LANG', true)
        })
        .catch(() => {
            store.commit('GET_LANG', true)
        })

}
/// i18n && router
const langs = ['ru', 'en', 'uz']
router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    if (language !== '') {
        if (!language || !langs.some(el => el == language)) {
            language = i18n.locale
            localStorage.setItem('lang', i18n.locale)
            loadLanguageAsync(i18n.locale).then(() => next({ path: '/' + i18n.locale + to.fullPath }))
        }

        i18n.locale = language
        localStorage.setItem('lang', language)
        loadLanguageAsync(language).then(() => next())
    } else next()

})



///
new Vue({
    render: h => h(App),
    store,
    i18n,
    router
}).$mount('#app')