<template>
  <div class="index-page">
    <section class="bg-main section-main">
      <div class="main-slider container-fluid">
        <comp-slider></comp-slider>
      </div>
      <mini-cards></mini-cards>
    </section>
    <section class="section-food">
      <div class="container">
        <div class="section-food-header">
          <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
              <div class="text-lg">
                {{ $t("Joylashgan hududingizdagi taomlar") }}
              </div>
              <div class="text-sm">
                {{ $t("text for subfilter, some text") }}
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="section-food-selects">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select">
                      <div class="custom-select">
                        <select
                          v-model="regionId"
                          @change="$store.dispatch('getDistricts', regionId)"
                        >
                          <option value="" disabled>
                            {{ $t("Regionni tanlang") }}
                          </option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.id"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select my-select-last">
                      <div class="custom-select">
                        <select
                          v-model="districtId"
                          @change="
                            $store.dispatch('filterOrgDis', {
                              regionId: regionId,
                              districtId: districtId,
                            })
                          "
                        >
                          <option value="" selected disabled>
                            {{ $t("Shaharni tanlang") }}
                          </option>
                          <option
                            v-for="district in districts"
                            :key="district.id"
                            :value="district.id"
                          >
                            {{ district.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="food-menu">
            <ul class="bottom-border" v-if="regions.length > 0">
              <li>
                <a href="#!" v-if="regionId !== ''">{{
                  regions.find((el) => el.id == regionId).name
                }}</a>
              </li>
              <li>
                <a href="#!" v-if="districtId !== ''">{{
                  districts.find((el) => el.id == districtId).name
                }}</a>
              </li>
            </ul>
            <section class="rek-menu">
              <div class="row" v-if="filteredOrgs.length > 0">
                <restoran-card
                  v-for="item in regionId == ''
                    ? filteredOrgs.slice(0, 6)
                    : filteredOrgs"
                  :key="item.id"
                  :adress="item.address"
                  :legal_name="item.title"
                  :orgId="item.id"
                  :contact="item.contact"
                  :orgLogo="item.image"
                >
                </restoran-card>
              </div>
            </section>
          </div>
          <div class="more-button">
            <router-link
              @click.native="$store.dispatch('allOrganization')"
              :to="'/' + $i18n.locale + '/organization'"
            >
              {{ $t("Barchasi") }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="app-section">
      <div class="container app-section-wrapper position-relative">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-7 col-md-8 col-sm-8 col-12">
              <div class="app-card">
                <div class="app-title">{{ $t("Loyiha nomi") }}</div>
                <div
                  class="app-sub"
                  v-if="loyiha !== ''"
                  v-html="loyiha.description"
                ></div>

                <div class="app-links">
                  <div
                    class="app-google mar-bot"
                    v-if="play_market !== undefined"
                  >
                    <a :href="play_market.value">
                      <div class="link-img">
                        <img src="../../assets/images/icons/g-play.svg" />
                      </div>
                      <div class="link-texts">
                        <div class="link-title">{{ play_market.title }}</div>
                        <div class="link-sub">Google Play</div>
                      </div>
                    </a>
                  </div>
                  <div class="app-apple" v-if="app_store !== undefined">
                    <a :href="app_store.value">
                      <div class="link-img">
                        <img src="../../assets/images/icons/app-store.svg" />
                      </div>
                      <div class="link-texts">
                        <div class="link-title">{{ app_store.title }}</div>
                        <div class="link-sub">App Store</div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="app-logos">
                  <a href="https://moqqv.uz" class="mahalla-logo">
                    <img src="@/assets/images/gerb.svg" alt="" />
                    <div class="app-logo-title">
                      O'zbekiston Respublikasi<br />Mahalla va oilani
                      qo'llab-quvvatlash vazirligi
                    </div>
                  </a>
                  <a href="http://gastrotourism.uz/" class="logo-gastro">
                    <img src="@/assets/images/gastrotourism.png" alt="" />
                  </a>
                  <a href="https://uzbekistan.travel">
                    <img src="@/assets/images/uzbekistan.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-5 col-md-4 col-sm-4 col-12">
              <div class="app-sec-img">
                <img src="../../assets/images/png/big-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="news-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-6">
            <div class="news-card">
              <div class="news-title">{{ $t("Yangiliklar") }}</div>
              <div class="row" v-if="posts.length > 0">
                <div
                  class="col-10 col-sm-6"
                  v-for="post in posts"
                  :key="post.id"
                >
                  <news-card
                    :title="post.title"
                    :anons="post.anons"
                    :date="post.updated_at"
                    :slug="post.slug"
                    :postId="post.id"
                  >
                  </news-card>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-6">
            <div class="news-map">
              <div class="map-title">{{ $t("Mashhur hududlar") }}</div>
              <div class="n-sub">
                {{ $t("Mashhur hududlar subtext") }}
              </div>
              <div class="map">
                <my-map></my-map>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="registration-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-12 col-lg-6">
            <div class="reg-card reg-card-left">
              <div class="reg-card-header">
                {{ $t("Turist sifatida ro’yhatdan o‘tish tartibi") }}
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo1.svg" alt="" />
                <p>
                  {{
                    $t(
                      "Mobil ilova yoki veb saytimizdan ro’yhatdan o’tish tugmasini bosasiz"
                    )
                  }}
                </p>
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo2.svg" alt="" />
                <p>
                  {{ $t("Ism sharifingizni va telefon raqamingizni kiriting") }}
                </p>
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo3.svg" alt="" />
                <p>
                  {{
                    $t(
                      "Mobil ilovamizni yuklab oling va taomlarga buyurtma bering"
                    )
                  }}
                </p>
              </div>
              <div class="mini-cards" v-if="!this.$store.state.authorized">
                <router-link
                  :to="'/' + $i18n.locale + '/signUp'"
                  @click.native="$store.commit('CHANGE_SIGNUP_TYPE', 'turist')"
                  class="mini-card"
                >
                  <div class="text">
                    <div class="text-lg">{{ $t("Mahalliy taomlar") }}</div>
                    <div class="text-sm">
                      {{ $t("Turist sifatida ro‘yhatdan o‘ting") }}
                    </div>
                  </div>
                  <div class="go-btn">
                    <img
                      src="../../assets/images/icons/Arrow - Right.svg"
                      alt=""
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-md-10 col-sm-12 col-lg-6">
            <div class="reg-card reg-card-right">
              <div class="reg-card-header">
                {{ $t("Oshxona sifatida ro’yhatdan o‘tish tartibi") }}
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo1.svg" alt="" />
                <p>
                  {{
                    $t(
                      "Mobil ilova yoki veb saytimizdan ro’yhatdan o’tish tugmasini bosasiz"
                    )
                  }}
                </p>
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo5.svg" alt="" />
                <p>
                  {{
                    $t(
                      "Oshxona nomi aloqa vositasi va joylashgan hududini kiriting"
                    )
                  }}
                </p>
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo6.svg" alt="" />
                <p>{{ $t("Taom turi, nomi va suratini kiriting") }}</p>
              </div>
              <div class="reg-info">
                <img src="../../assets/images/icons/reginfo3.svg" alt="" />
                <p>
                  {{
                    $t(
                      "Mobil ilovamizni yuklab oling va taomlarga buyurtma bering"
                    )
                  }}
                </p>
              </div>
              <div class="mini-cards" v-if="!this.$store.state.authorized">
                <router-link
                  :to="'/' + $i18n.locale + '/signUp'"
                  @click.native="$store.commit('CHANGE_SIGNUP_TYPE', 'cheef')"
                  class="mini-card"
                >
                  <div class="text">
                    <div class="text-lg">
                      {{ $t("Milliy taomlar pishirasizmi?") }}
                    </div>
                    <div class="text-sm">
                      {{ $t("Oshxona sifatida ro‘yhatdan o‘ting") }}
                    </div>
                  </div>
                  <div href="#!" class="go-btn">
                    <img
                      src="../../assets/images/icons/Arrow - Right.svg"
                      alt=""
                    />
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <contact-us></contact-us>
  </div>
</template>

<script>
import compSlider from "../main/Slider";
import miniCards from "../elements/MiniCards";
import contactUs from "../elements/ContactUs";
import newsCard from "../elements/NewsCard";
import myMap from "../elements/Map";
import restoranCard from "../elements/RestoranCard";
import { mapState } from "vuex";
export default {
  components: {
    compSlider,
    miniCards,
    contactUs,
    newsCard,
    myMap,
    restoranCard,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    windowSize() {
      if (window.innerWidth > 767) return true;
      return false;
    },
    ...mapState([
      "organizations",
      "posts",
      "settings",
      "regions",
      "districts",
      "filteredOrgs",
      "loyiha",
    ]),
    regionId: {
      get() {
        return this.$store.state.regionId;
      },
      set(value) {
        this.$store.commit("SET_REGION_ID", value);
      },
    },
    districtId: {
      get() {
        return this.$store.state.districtId;
      },
      set(value) {
        this.$store.commit("SET_DISTRICT_ID", value);
      },
    },
    app_store() {
      return this.settings.find((el) => el.slug == "app_store");
    },
    play_market() {
      return this.settings.find((el) => el.slug == "play_market");
    },
  },
  mounted() {
    this.$store.commit("SET_HEADER", false);
    this.$store.dispatch("loyiha");
  },
  destroyed() {
    this.$store.commit("SET_HEADER", true);
  },
};
</script>
<style scoped>
.topshiriq-1 {
  background: #e5e5e5;
}
.add-point {
  display: flex;
}
.add-point button {
  background: teal;
}
</style>

<style>
.app-logos {
  margin-top: 110px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 776px) {
  .app-logos {
    flex-direction: column;
    align-items: flex-start;
  }
  .app-logos a {
    margin-bottom: 15px;
  }
}
.app-logos a {
  display: flex;
  align-items: center;
}
.app-logos img {
  width: 120px;
  object-fit: contain;
}
.mahalla-logo img {
  height: 70px;
}
.app-logos .app-logo-title {
  font-family: baloo2;
  font-weight: 700;
  color: #252322;
  line-height: 1.2;
  font-size: 14px;
  width: 150px;
}
.position-relative {
  position: relative;
}
.section-food-selects .my-select .custom-select select {
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0 20px;
}
.section-food-selects .my-select .custom-select option {
  padding: 10px 0;
  background: #f8f8f8;
}
.section-food-selects .my-select .custom-select {
  padding: 0;
}
.map {
  border-radius: 50px;
  height: 471px;
  padding: 10px 40px 30px 10px;
  background-color: #ff6711;
  background-image: url(/img/map-bg.55069d72.svg);
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;
  height: 470px;
  background-repeat: no-repeat;
  margin-bottom: 80px;
}
.bg-mini-card2.mini-cards {
  padding-bottom: 0;
}
.index-page .registration-section .reg-card.reg-card-left {
  height: 100%;
}
@media (max-width:768px){
  .index-page .registration-section .reg-card.reg-card-left {
  height: auto;
}
}
.bg-mini-card2.mini-cards .mini-card {
  background: #f8f8f8;
}
.registration-section .mini-cards {
  width: 100%;
  padding-bottom: 0;
  margin-top: auto;
}
</style>
<style>
@media (max-width: 768px) {
  .map {
    padding: 10px;
    height: 400px;
    margin-bottom: 50px;
  }
  .index-page .registration-section {
    margin-top: 25px;
  }
}
</style>