<template>
  <div class="about-us-page eat-page">
    <section class="section-food">
      <div class="container">
        <div class="section-food-header">
          <div class="row justify-content-between">
            <div class="col-md-6 col-sm-12">
              <div class="text-lg">
                {{ $t("Joylashgan hududingizdagi taomlar") }}
              </div>
              <div class="text-sm">
                Demonstrate the visual form of a document or a typeface without
                relying on meaningful.
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <div class="section-food-selects">
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select">
                      <div class="custom-select">
                        <select
                          v-model="regionId"
                          @change="
                            $store.dispatch('getDistricts', regionId),
                              (districtId = '')
                          "
                        >
                          <option value="" disabled>
                            {{ $t("Shaharni tanlang") }}
                          </option>
                          <option
                            v-for="region in regions"
                            :key="region.id"
                            :value="region.id"
                          >
                            {{ region.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <div class="my-select my-select-last">
                      <div class="custom-select">
                        <select
                          v-model="districtId"
                          @change="
                            $store.dispatch('filterOrgDis', {
                              regionId: regionId,
                              districtId: districtId,
                            })
                          "
                        >
                          <option value="" selected disabled>
                            {{ $t("Regionni tanlang") }}
                          </option>
                          <option
                            v-for="district in districts"
                            :key="district.id"
                            :value="district.id"
                          >
                            {{ district.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="food-menu">
            <ul class="bottom-border" v-if="regions.length > 0">
              <li>
                <a href="#!" v-if="regionId !== ''">{{
                  regions.find((el) => el.id == regionId).name
                }}</a>
              </li>
              <li>
                <a href="#!" v-if="districtId !== ''">{{
                  districts.find((el) => el.id == districtId).name
                }}</a>
              </li>
            </ul>
            <div class="myMap">
              <my-map></my-map>
            </div>
            <section class="rek-menu">
              <div class="title">
                {{
                  $t("Kiritilgan parametr bo’yicha mavjud oshxonalar ro’yhati")
                }}
              </div>
              <div class="row" v-if="organizations.length > 0">
                <restoran-card
                  v-for="item in organizations"
                  :key="item.id"
                  :adress="item.address"
                  :legal_name="item.title"
                  :orgId="item.id"
                  :contact="item.contact"
                  :orgLogo="item.logo"
                >
                </restoran-card>
              </div>
            </section>
            <div
              class="more-button"
              v-if="$store.state.orgCurrentPage <= $store.state.orgPageCount"
            >
              <a @click="$store.dispatch('getOrganizationsMore')">Ko'proq</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="au-first">
      <mini-cards></mini-cards>
    </section>
  </div>
</template>

<script>
import miniCards from "../elements/MiniCards.vue";
import RestoranCard from "../elements/RestoranCard.vue";
import myMap from "../elements/Map.vue";
import { mapState } from "vuex";

export default {
  components: {
    miniCards,
    RestoranCard,
    myMap,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["organizations", "regions", "districts"]),
    regionId: {
      get() {
        return this.$store.state.regionId;
      },
      set(value) {
        this.$store.commit("SET_REGION_ID", value);
      },
    },
    districtId: {
      get() {
        return this.$store.state.districtId;
      },
      set(value) {
        this.$store.commit("SET_DISTRICT_ID", value);
      },
    },
  },
  mounted() {
    this.$store.dispatch("getOrganizations");
  },
};
</script>

<style>
</style>