<template>
  <div
    class="mini-cards container"
    :class="addClass"
    v-if="!this.$store.state.authorized"
  >
    <div class="row">
      <div class="col-12 col-sm-9 col-md-8 col-lg-6">
        <router-link
          :to="'/' + $i18n.locale + '/signUp'"
          @click.native="$store.commit('CHANGE_SIGNUP_TYPE', 'turist');$store.commit('SHOW_SIGNUP_TYPE', false)"
          class="mini-card"
        >
          <div class="text">
            <div class="text-lg">{{ $t("Mahalliy taomlar") }}</div>
            <div class="text-sm">
              {{ $t("Turist sifatida ro‘yhatdan o‘ting") }}
            </div>
          </div>
          <div class="go-btn">
            <img src="../../assets/images/icons/Arrow - Right.svg" alt="" />
          </div>
        </router-link>
      </div>
      <div class="col-12 col-sm-9 col-md-8 col-lg-6">
        <router-link
          :to="'/' + $i18n.locale + '/signUp'"
          @click.native="$store.commit('CHANGE_SIGNUP_TYPE', 'cheef');$store.commit('SHOW_SIGNUP_TYPE', false)"
          class="mini-card"
        >
          <div class="text">
            <div class="text-lg">{{ $t("Milliy taomlar pishirasizmi?") }}</div>
            <div class="text-sm">
              {{ $t("Oshxona sifatida ro‘yhatdan o‘ting") }}
            </div>
          </div>
          <div href="#!" class="go-btn">
            <img src="../../assets/images/icons/Arrow - Right.svg" alt="" />
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["addClass"],
};
</script>

<style>
</style>