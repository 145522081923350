<template>
  <swiper class="swiper-container1" ref="mySwiper" :options="windowSize">
    <swiper-slide
      class="swiper-slide"
      v-for="(item, index) in banners"
      :key="index"
      :style="{ backgroundImage: 'url(' + item.image.thumbnails.medium + ')' }"
    >
      <div class="row sw-card" v-if="banners.length > 0">
        <div class="col-lg-5 col-md-6 col-sm-12 fit-content fit-content2">
          <div class="text-lg">
            {{ item.title }}
          </div>
          <div class="text-sm">
            {{ item.description }}
          </div>
          <router-link :to="'/' + $i18n.locale + '/more'" class="sw-card-btn">{{
            $t("batafsil")
          }}</router-link>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <div class="sw-card-img-wrap">
            <div class="sw-card-img" v-if="item.image !== null">
              <!-- <img :src="item.image.thumbnails.medium" alt="samsa" /> -->
              <div class="my-bg"></div>
            </div>
            <div class="sw-card-img" v-else>
              <img
                src="../../assets/images/default-img.svg"
                alt="img not found"
              />
              <div class="my-bg"></div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },

  data() {
    return {
      swiperOptions: {
        direction: "vertical",
        grabCursor: false,
        loop: true,
        effect: "cube",
        cubeEffect: {
          shadow: false,
          slideShadows: false,
          shadowOffset: 0,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: true
        },
        speed: 800,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperOptions2: {
        grabCursor: false,
        loop: true,
        effect: "cube",
        cubeEffect: {
          shadow: false,
          slideShadows: false,
          shadowOffset: 0,
          shadowScale: 0.94,
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: true
        },
        speed: 800,
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
    };
  },
  computed: {
    windowSize() {
      if (window.innerWidth > 767) return this.swiperOptions;
      return this.swiperOptions2;
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    ...mapState(["organizations", "banners"]),
  },
  mounted() {
    this.swiper.slideTo(1, 1000, false);
    this.$store.dispatch("banners");
  },
};
</script>

<style scoped>
.index-page .sw-card .sw-card-img {
  justify-content: flex-end;
}
.index-page .sw-card .sw-card-img img {
  width: 410px;
  height: 100%;
  border-radius: 50px;
}
.index-page .sw-card .sw-card-img .my-bg {
  display: none;
}
@media (max-width: 991px) {
  .sw-card-img-wrap {
    display: none;
  }
}
</style>
<style scoped>
.fit-content2 {
  padding: 20px 25px;
  background-color: rgb(255 255 255 / 38%);
  border-radius: 12px;
  color: #000;
  font-weight: bold;
}
.sw-card-btn {
  background: #fc772c;
  box-shadow: 0 24px 44px rgb(255 103 17 / 27%);
  border-radius: 12px;
}
@media (max-width: 768px) {
  .sw-card-btn {
    display: none;
  }
  .fit-content2 {
    display: none !important;
  }
}
</style>