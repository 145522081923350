import indexComp from '../components/pages/Index.vue'
import searchComp from '../components/pages/Search.vue'
import { store } from '../store/store'

function lazyLoad(path) {
    return () =>
        import ( /*webpackChunkName: "[request]"*/ `@/components/pages/${path}.vue`)
}
export const routes = [{
        path: '/',
        component: indexComp,
        redirect: localStorage.getItem('lang') || 'ru'
    },
    {
        path: '/:lang',
        component: {
            render(c) { return c('router-view') },
        },
        children: [{
                path: '/',
                component: indexComp
            },
            {
                path: 'about',
                component: lazyLoad('About')
            },
            {
                path: 'more',
                component: lazyLoad('More')
            },
            {
                path: 'news',
                component: lazyLoad('News')
            },
            {
                path: 'news/:id/:name',
                component: lazyLoad('single/New')
            },
            {
                path: 'organization',
                component: lazyLoad('Organization')
            },
            {
                path: 'organization/:id/:name',
                component: lazyLoad('single/Organization')
            },
            {
                path: 'organization/:orgId/products/:dishId',
                name: 'eat-single',
                component: lazyLoad('single/Eat')
            },
            {
                path: 'contact',
                component: lazyLoad('Contact')
            },
            {
                path: 'search',
                component: searchComp
            },
            {
                path: 'dashboard/eats',
                component: lazyLoad('organization/Eats')
            },
            {
                path: 'dashboard/eats/add',
                component: lazyLoad('organization/Eats-add')
            },
            {
                path: 'dashboard/settings',
                component: lazyLoad('organization/Settings')
            },
            {
                path: 'dashboard/bookings',
                component: lazyLoad('organization/Bookings')
            },
            {
                path: 'settings',
                name: 'user-settings',
                component: lazyLoad('user/Settings'),
                beforeEnter: (to, from, next) => {
                    if (store.state.authorized) next()
                    else next('/')
                }
            },
            {
                path: 'bookings',
                name: 'userBooking',
                component: lazyLoad('user/Bookings'),
                beforeEnter: (to, from, next) => {
                    if (store.state.authorized) next()
                    else next('/')
                }
            },
            {
                path: 'signUp',
                name: 'signUp',
                component: lazyLoad('SignUp'),
                beforeEnter: (to, from, next) => {
                    if (!store.state.authorized) next()
                    else next('/')
                }
            },
            {
                path: 'signIn',
                name: 'signIn',
                component: lazyLoad('SignIn'),
                beforeEnter: (to, from, next) => {
                    if (!store.state.authorized) next()
                    else next('/')
                }
            },
            {
                path: '*',
                component: indexComp,
            }

        ]
    },
    {
        path: '*',
        component: indexComp,
    }
]